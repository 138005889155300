/* eslint-disable max-len, vue/max-len */
/**
 * @file 出海易商家服务协议
 * @author dingxin02@baidu.com
 */

export const data = {
    'zh-CN': {
        title: '数据处理规则说明及合规要求',
        publishTime: '版本日期：2022-08-09',
        content: `
            <p><strong><strong>请您（以下亦称&ldquo;商家&rdquo;）在使用出海易服务前，仔细阅读并充分理解本合规要求，并遵守本合规要求进行相关操作。重点内容我们已采用粗体标示，希望您特别关注。</strong></strong></p>
            <p><strong><strong>出海易平台有权根据相关法律、法规、政策的要求及提供服务的需要不时地修改本合规要求并在出海易平台相关版块发布，请您适时注意本合规要求的变更。若您在本合规要求变更后继续使用出海易平台的各项服务</strong></strong><strong><strong>/软件，表示您已经阅读、理解并接受修改后的</strong></strong><strong><strong>要求</strong></strong><strong><strong>和具体规则内容，也将遵循修改后的</strong></strong><strong><strong>要求</strong></strong><strong><strong>和具体规则使用出海易各项服务/软件；同时就您在</strong></strong><strong><strong>合规要求</strong></strong><strong><strong>和具体规则修订前通过出海易平台进行的商业活动行为及其效力，视为您已同意并已按照本</strong></strong><strong><strong>合规要求</strong></strong><strong><strong>及有关规则进行了相应的授权和追认。若您不同意修改后的</strong></strong><strong><strong>合规要求</strong></strong><strong><strong>内</strong></strong><strong><strong>容，您应立即停止使用出海易相关的服务。</strong></strong></p>
            <p>1、您通过出海易提供的服务，收集、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的数据，您完全拥有您的数据。<strong><strong>出海易作为中立的技术服务提供者，会严格执行您的委托和指示，除按与您协商一致或依据特定产品规则或基于您的要求为您提供技术协助进行故障排除或解决技术问题或执行明确的法律法规要求外，我们不对您的数据进行任何非授权的访问、使用或披露。</strong></strong></p>
            <p>2、<strong><strong>您应对您的数据来源及内容负责，我们提示您谨慎判断数据来源及内容的合法性</strong></strong>。您应保证您有权使用我们的产品、服务对该等数据进行处理，且前述处理活动均符合相应国家的相关法律法规的要求，不存在任何违法违规、侵权或违反与第三方合同约定的情形，亦不会将数据用于违法违规目的。<strong><strong>因您的数据内容违反相关国家法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。您应遵守与处理用户个人信息有关的所有可适用的法律、政策和法规，保护用户个人信息安全。</strong></strong></p>
            <p><strong><strong>3、</strong></strong>当我们根据您的委托和指示处理您的业务数据时，如果涉及第三方个人信息，您应当依法提前向相关个人信息主体履行告知义务，取得相关个人信息主体的单独同意，并保证：</p>
            <p>（1）<strong><strong>您在使用出海易平台提供产品和服务过程中对用户必要的个人信息的收集、使用和保护提供《用户协议》和《隐私协议》，您应该在页面的显著位置或以其他可触达用户的方式告知用户</strong></strong>（包括但不限于：在&ldquo;提交&rdquo;操作前为用户提供勾选框&ldquo;我已充分阅读并理解《用户协议》和《隐私政策》，同意前述协议相关约定，并在页面上提供用户可浏览《用户协议》和《隐私政策》的链接），并就您使用出海易产品和服务期间出海易处理用户相关个人信息事宜获得用户的完整、合法、持续有效的授权同意。</p>
            <p>（2）您应在网页在浏览器页面底部或顶部以横条或悬浮窗形式向用户弹窗提示用户阅读Cookie政策并征求用户同意（可参考下图），Cookie政策中应包含提供相关cookie的收集目的、保存期限以及用户对cookie进行管理的方式、第三方cookie相关基本信息。</p>
            <p>（3）<strong><strong>如您的相关网页产品服务可能面向儿童提供，从而可能收集儿童个人信息，需要满足所适用法律中对儿童个人信息保护的要求，</strong></strong>例如您在美国收集儿童个人信息，您需要通过弹窗、放置在页面显著位置等方式放置监护人告知的链接，告知监护人网页将收集使用儿童个人信息的情况。同时，您需要在监护人阅读告知后，设计COPPA认可的机制征得监护人的同意，例如在监护人告知的文末提供监护人签署的同意表，并要求监护人通过邮寄、传真或电子扫描的方式向您提供等，具体征得同意的方式可以参考COPPA312.5(b)的规定。</p>
            <p>COPPA 312.5 (b)：经营者获取父母同意的方式包括：提供父母签署的同意表并通过邮寄、传真或电子扫描的方式还至经营者；在涉及资金交易的场景，要求父母使用信用卡、借记卡或其他会向主要账户持有人提供通知的在线支付系统；要求父母拨打接话员需经过相关培训的免费电话；通过政府的身份信息数据库来验证父母政府签发的身份证信息，并在核查完成后立即从记录中删除相关信息；或者，在经营者不公开儿童个人信息的情况下，使用电子邮件及额外验证步骤进行确认，额外验证步骤包括收到同意后发送确认邮件，或在从父母处获取邮寄地址、电话号码后以用信件或电话方式确认，使用该方式必须告知父母其可以撤回在之前邮件中作出的任何同意。</p>
            <p>（4）您应向用户提供易于操作的查阅、更正、补充、删除、复制或转移其个人信息，撤回或更改其授权同意，要求您就个人信息处理规则作出解释说明等用户权利实现机制。</p>
            <p>4、您承诺在使用用户个人信息时应当遵守适用的个人信息保护规定，保护个人信息主体的合法权益，独立负责响应个人信息主体的权利请求、处理个人信息主体的投诉、接受监管机构的检查和调查等并承担相应的法律责任。如果涉及数据跨境传输，您需确保按照数据输出国的要求进行数据跨境传输，遵守相应国家的法律法规规定，按照相应国家要求进行审批或备案。其中，涉及从中华人民共和国出境的数据，请严格遵守《中华人民共和国网络安全法》、《中华人民共和国数据安全法》、《中华人民共和国个人信息保护法》等相关法律规定，并根据数据出境情况进行相关审批。</p>
            <p>5、您理解并同意，您应根据自身需求自行对您的数据进行存储，我们仅依据相关法律法规要求或特定的服务规则约定，接受您的委托为您提供数据存储服务。您理解并同意，除非法律法规另有规定或依据服务规则约定，我们不会访问、存储您的数据，亦不对您的数据存储工作或结果承担任何责任。目前您的独立站数据存储在【国外AWS数据库】，出海易CRM数据存储在【国内百度数据库】，</p>
            <p>【EDM】数据存储在中国境内服务器。<strong><strong>因俄罗斯、沙特、印度、越南对于个人信息数据有本地化存储的要求，除特殊约定，我们不针对这些国家提供服务，如您要在上述国家开展业务，请您自行按照当地法律要求进行数据存储（例如本地化存储及数据传输活动）。</strong></strong></p>
            <p>6、数据安全保障义务：您应遵守所有可适用法律的数据安全要求以及出海易的安全要求，确保服务过程不存在已知安全隐患或问题，保障网络免受干扰、破坏或者未经授权的访问。如您或关联公司在发生投资并购导致数据控制权发生转移时，应提前征得出海易同意，确保数据保密和安全政策不变。您应严格遵从双方的约定、出海易及/或用户的指示进行个人数据处理、转移及其他相关业务，采取数据分类、重要数据备份和加密等措施，防止网络数据泄露或者被窃取、篡改。未经出海易书面许可，不得使用个人便携设备、存储介质接入出海易的网络。未经出海易及/或用户书面授权，不得访问出海易及/或用户系统或收集、持有、处理、修改、泄漏、传播用户网络中的任何数据和信息。您应建立安全保障体系，实施产品及或服务的安全管理并定期进行安全自检，出海易有权对商家的安全保障体系及执行情况进行审核。您须建立安全应急响应机制，对产品及/或服务的安全问题（含安全漏洞）及问题的解决方案（含安全补丁），通过邮件、传真或其他书面方式向出海易进行通报。您应对关键安全岗位员工进行安全管理，包括但不限于与员工安全协议签署、进行安全培训、对员工遵守安全规范的情况进行审核及改善通过审核发现的问题。您应在研发、生产制造、物流和采购等环节建立详细的安全过程记录，以确保过程的可追溯性。若您的产品及/或服务含有日志，则日志应有访问控制，并按照规定留存相关的网络日志，例如中国《网络安全法》要求网络日志的留存不少于六个月。</p>
            <p>7、其他您应当遵守的所有可适用法律的数据安全及合规要求。</p>
        `,
    },
};
